<template>
    <div class="news xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>行业新闻</h3>
                    <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>行业新闻</a></p>
                </div>
            </div>
        </div>
        <div class="news-wrap clearfix">
            <div class="news-left left">
                <ul class="news-list">
                    <li class="news-item clearfix">
                        <div class="news-img left">
                            <img src="../../assets/img/news_img.jpg" alt="">
                        </div>
                        <div class="news-info left">
                            <h3>热烈祝贺我公司荣获2020年度最具影响力企业奖</h3>
                            <p>9月11日， 2020工业互联网创新峰会于线上盛大开幕，本届大会以“破而后立，智造新生”为主题，政商学界精英齐聚共话工业互联网热点， 围绕工业互联网技术创新、制造业数字化转型、工业互联网产业平台搭建等议题，探索工业互联网产业链新模式，构建工业互联网新生态的同时， 大会也为2019-2020年度中最能代表互联网行业发展的优秀企业献上荣誉。我公司 凭借在行业的持续创新和稳健表现， 荣获“ 2019-2020年度互联网产业最具影响力企业”奖项。</p>
                            <div class="news-author clearfix">
                                <div class="author-headImg left">
                                    <img src="../../assets/img/head_img.png" width="100%" alt="">
                                </div>
                                <div class="author-mes left">
                                    <p>诺亚医疗</p>
                                    <span>2021-05-12</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="news-item clearfix">
                        <div class="news-img left">
                            <img src="../../assets/img/news_img.jpg" alt="">
                        </div>
                        <div class="news-info left">
                            <h3>热烈祝贺我公司荣获2020年度最具影响力企业奖</h3>
                            <p>9月11日， 2020工业互联网创新峰会于线上盛大开幕，本届大会以“破而后立，智造新生”为主题，政商学界精英齐聚共话工业互联网热点， 围绕工业互联网技术创新、制造业数字化转型、工业互联网产业平台搭建等议题，探索工业互联网产业链新模式，构建工业互联网新生态的同时， 大会也为2019-2020年度中最能代表互联网行业发展的优秀企业献上荣誉。我公司 凭借在行业的持续创新和稳健表现， 荣获“ 2019-2020年度互联网产业最具影响力企业”奖项。</p>
                            <div class="news-author clearfix">
                                <div class="author-headImg left">
                                    <img src="../../assets/img/head_img.png" width="100%" alt="">
                                </div>
                                <div class="author-mes left">
                                    <p>诺亚医疗</p>
                                    <span>2021-05-12</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="news-item clearfix">
                        <div class="news-img left">
                            <img src="../../assets/img/news_img.jpg" alt="">
                        </div>
                        <div class="news-info left">
                            <h3>热烈祝贺我公司荣获2020年度最具影响力企业奖</h3>
                            <p>9月11日， 2020工业互联网创新峰会于线上盛大开幕，本届大会以“破而后立，智造新生”为主题，政商学界精英齐聚共话工业互联网热点， 围绕工业互联网技术创新、制造业数字化转型、工业互联网产业平台搭建等议题，探索工业互联网产业链新模式，构建工业互联网新生态的同时， 大会也为2019-2020年度中最能代表互联网行业发展的优秀企业献上荣誉。我公司 凭借在行业的持续创新和稳健表现， 荣获“ 2019-2020年度互联网产业最具影响力企业”奖项。</p>
                            <div class="news-author clearfix">
                                <div class="author-headImg left">
                                    <img src="../../assets/img/head_img.png" width="100%" alt="">
                                </div>
                                <div class="author-mes left">
                                    <p>诺亚医疗</p>
                                    <span>2021-05-12</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="news-item clearfix">
                        <div class="news-img left">
                            <img src="../../assets/img/news_img.jpg" alt="">
                        </div>
                        <div class="news-info left">
                            <h3>热烈祝贺我公司荣获2020年度最具影响力企业奖</h3>
                            <p>9月11日， 2020工业互联网创新峰会于线上盛大开幕，本届大会以“破而后立，智造新生”为主题，政商学界精英齐聚共话工业互联网热点， 围绕工业互联网技术创新、制造业数字化转型、工业互联网产业平台搭建等议题，探索工业互联网产业链新模式，构建工业互联网新生态的同时， 大会也为2019-2020年度中最能代表互联网行业发展的优秀企业献上荣誉。我公司 凭借在行业的持续创新和稳健表现， 荣获“ 2019-2020年度互联网产业最具影响力企业”奖项。</p>
                            <div class="news-author clearfix">
                                <div class="author-headImg left">
                                    <img src="../../assets/img/head_img.png" width="100%" alt="">
                                </div>
                                <div class="author-mes left">
                                    <p>诺亚医疗</p>
                                    <span>2021-05-12</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="news-item clearfix">
                        <div class="news-img left">
                            <img src="../../assets/img/news_img.jpg" alt="">
                        </div>
                        <div class="news-info left">
                            <h3>热烈祝贺我公司荣获2020年度最具影响力企业奖</h3>
                            <p>9月11日， 2020工业互联网创新峰会于线上盛大开幕，本届大会以“破而后立，智造新生”为主题，政商学界精英齐聚共话工业互联网热点， 围绕工业互联网技术创新、制造业数字化转型、工业互联网产业平台搭建等议题，探索工业互联网产业链新模式，构建工业互联网新生态的同时， 大会也为2019-2020年度中最能代表互联网行业发展的优秀企业献上荣誉。我公司 凭借在行业的持续创新和稳健表现， 荣获“ 2019-2020年度互联网产业最具影响力企业”奖项。</p>
                            <div class="news-author clearfix">
                                <div class="author-headImg left">
                                    <img src="../../assets/img/head_img.png" width="100%" alt="">
                                </div>
                                <div class="author-mes left">
                                    <p>诺亚医疗</p>
                                    <span>2021-05-12</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="news-item clearfix">
                        <div class="news-img left">
                            <img src="../../assets/img/news_img.jpg" alt="">
                        </div>
                        <div class="news-info left">
                            <h3>热烈祝贺我公司荣获2020年度最具影响力企业奖</h3>
                            <p>9月11日， 2020工业互联网创新峰会于线上盛大开幕，本届大会以“破而后立，智造新生”为主题，政商学界精英齐聚共话工业互联网热点， 围绕工业互联网技术创新、制造业数字化转型、工业互联网产业平台搭建等议题，探索工业互联网产业链新模式，构建工业互联网新生态的同时， 大会也为2019-2020年度中最能代表互联网行业发展的优秀企业献上荣誉。我公司 凭借在行业的持续创新和稳健表现， 荣获“ 2019-2020年度互联网产业最具影响力企业”奖项。</p>
                            <div class="news-author clearfix">
                                <div class="author-headImg left">
                                    <img src="../../assets/img/head_img.png" width="100%" alt="">
                                </div>
                                <div class="author-mes left">
                                    <p>诺亚医疗</p>
                                    <span>2021-05-12</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="news-item clearfix">
                        <div class="news-img left">
                            <img src="../../assets/img/news_img.jpg" alt="">
                        </div>
                        <div class="news-info left">
                            <h3>热烈祝贺我公司荣获2020年度最具影响力企业奖</h3>
                            <p>9月11日， 2020工业互联网创新峰会于线上盛大开幕，本届大会以“破而后立，智造新生”为主题，政商学界精英齐聚共话工业互联网热点， 围绕工业互联网技术创新、制造业数字化转型、工业互联网产业平台搭建等议题，探索工业互联网产业链新模式，构建工业互联网新生态的同时， 大会也为2019-2020年度中最能代表互联网行业发展的优秀企业献上荣誉。我公司 凭借在行业的持续创新和稳健表现， 荣获“ 2019-2020年度互联网产业最具影响力企业”奖项。</p>
                            <div class="news-author clearfix">
                                <div class="author-headImg left">
                                    <img src="../../assets/img/head_img.png" width="100%" alt="">
                                </div>
                                <div class="author-mes left">
                                    <p>诺亚医疗</p>
                                    <span>2021-05-12</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <button class="more-btn">更多新闻</button>
            </div>
            <div class="news-right right">
                <div class="hot-news">
                    <div class="hot-newstit">
                        <h3>热门新闻</h3>
                    </div>
                    <div class="hot-news-list">
                        <div class="hot-news-list">
                            <div class="hot-news-img-first">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info-first">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                        <div class="hot-news-list clearfix">
                            <div class="hot-news-img left">
                                <img src="../../assets/img/news_img.jpg" alt="">
                            </div>
                            <div class="hot-news-info left">
                                <p>古巴与印度：全民免费医疗的宿命</p>
                                <span>2021-05-12</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer';
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    export default {
        name: "news",
        components: {
            ftCom,
            nhCom,
            rtBar
        },
    }
</script>

<style scoped>
    .news{
        background: #f7f7f7;
    }
    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg")center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

    .banner-text h3 {
        padding-top: 35px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 14px;
        color: #ffffff;
        margin-top: 20px;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
/*---------*/
    .news-wrap{
        width: 1200px;
        margin: 35px auto;
    }
    .news-wrap .news-left{
        width: 880px;
    }
    .news-wrap .news-right{
        width: 300px;
    }
    .news-left .more-btn{
        width: 100%;
        background: #FFFFFF;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        color: #666666;
    }
    .news-left .more-btn:hover{
        cursor: pointer;
        background: #1fb7a6;
        color: #FFFFFF;
    }
    .news-list .news-item{
        background: #FFFFFF;
        padding: 25px;
        margin-bottom: 20px;
    }

    .news-list .news-item .news-img{
        width: 240px;
        height: 145px;
        overflow: hidden;
        background: #e5e5e5;
        line-height: 145px;
    }
    .news-list .news-item .news-img img{
        display: inline-block;
        width: 100%;

        vertical-align: middle;
    }
    .news-list .news-item .news-info{
        width: calc(100% - 260px);
        margin-left: 10px;
    }
    .news-list .news-item .news-info h3{
        font-size: 22px;
        color: #333333;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .news-list .news-item .news-info p{
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .news-list .news-item .news-author{
        margin-top: 15px;
    }
    .news-list .news-item .news-author .author-headImg{
        margin-right: 5px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
    }
    .news-list .news-item .news-author .author-mes{
        line-height: 1.3;
    }
    .news-list .news-item .news-author .author-mes span{
        font-size: 12px;
        color: #999999;
    }
/*----------*/
    .hot-news{
        padding: 15px;
        box-sizing: border-box;
        background: #FFFFFF;
    }
    .hot-news .hot-newstit{
        border-bottom: 1px solid #e5e5e5;
    }
    .hot-news h3{
        display: inline-block;
        border-bottom: 2px solid #1fb7a6;
        font-size: 18px;
        color: #333333;
    }
    .hot-news-list{
        margin: 15px 0;
    }
    .hot-news-img-first{
        width: 100%;
        height: 150px;
        background: #e5e5e5;
        line-height: 150px;
        overflow: hidden;
    }
    .hot-news-list .hot-news-img{
        width: 102px;
        height: 60px;
        background: #e5e5e5;
        line-height: 60px;
        overflow: hidden;
    }
    .hot-news-list .hot-news-img img,.hot-news-list .hot-news-img-first img{
        width: 100%;
    }
    .hot-news-list .hot-news-info{
        width: calc(100% - 112px);
        margin-left: 10px;
    }
    .hot-news-list .hot-news-info-first p{
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .hot-news-list .hot-news-info p{
        color: #333333;
        line-height: 1.3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .hot-news-list .hot-news-info span,.hot-news-list .hot-news-info-first span{
        color: #999999;
        font-size: 12px;
    }
</style>
